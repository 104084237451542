import React from "react"

import "./Steps.scss"

function Steps() {
  return (
    <section
      id="workArea"
      className="section work-area bg-overlay overflow-hidden ptb_100"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            {/* Work Content */}
            <div className="work-content text-center section-heading-accent">
              <h2 className="text-white">How does it work?</h2>
              <p className="text-white d-block mt-4">
                The development process is very simple. <br />
                Here is how it looks like:
              </p>
            </div>
          </div>
        </div>
        <div className="row work-icons-wrapper">
          <div className="col-12 col-md-4">
            {/* Single Work */}
            <div className="single-work text-center p-3">
              {/* Work Icon */}
              <div className="work-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -26 512 512">
                  <path d="m256 100c-5.519531 0-10 4.480469-10 10s4.480469 10 10 10 10-4.480469 10-10-4.480469-10-10-10zm0 0" />
                  <path d="m90 280c5.519531 0 10-4.480469 10-10s-4.480469-10-10-10-10 4.480469-10 10 4.480469 10 10 10zm0 0" />
                  <path d="m336 0c-90.027344 0-163.917969 62.070312-169.632812 140.253906-85.738282 4.300782-166.367188 66.125-166.367188 149.746094 0 34.945312 13.828125 68.804688 39 95.632812 4.980469 20.53125-1.066406 42.292969-16.070312 57.296876-2.859376 2.859374-3.714844 7.160156-2.167969 10.898437 1.546875 3.734375 5.191406 6.171875 9.238281 6.171875 28.519531 0 56.003906-11.183594 76.425781-30.890625 19.894531 6.78125 45.851563 10.890625 69.574219 10.890625 90.015625 0 163.898438-62.054688 169.628906-140.222656 20.9375-.929688 42.714844-4.796875 59.945313-10.667969 20.421875 19.707031 47.90625 30.890625 76.425781 30.890625 4.046875 0 7.691406-2.4375 9.238281-6.171875 1.546875-3.738281.691407-8.039063-2.167969-10.898437-15.003906-15.003907-21.050781-36.765626-16.070312-57.296876 25.171875-26.828124 39-60.6875 39-95.632812 0-86.886719-86.839844-150-176-150zm-160 420c-23.601562 0-50.496094-4.632812-68.511719-11.800781-3.859375-1.539063-8.269531-.527344-11.078125 2.539062-12.074218 13.199219-27.773437 22.402344-44.878906 26.632813 9.425781-18.058594 11.832031-39.347656 6.097656-59.519532-.453125-1.589843-1.292968-3.042968-2.445312-4.226562-22.6875-23.367188-35.183594-53.066406-35.183594-83.625 0-70.46875 71.4375-130 156-130 79.851562 0 150 55.527344 150 130 0 71.683594-67.289062 130-150 130zm280.816406-186.375c-1.152344 1.1875-1.992187 2.640625-2.445312 4.226562-5.734375 20.171876-3.328125 41.460938 6.097656 59.519532-17.105469-4.226563-32.804688-13.433594-44.878906-26.632813-2.808594-3.0625-7.21875-4.078125-11.078125-2.539062-15.613281 6.210937-37.886719 10.511719-58.914063 11.550781-2.921875-37.816406-21.785156-73.359375-54.035156-99.75h130.4375c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-161.160156c-22.699219-11.554688-48.1875-18.292969-74.421875-19.707031 5.746093-67.164063 70.640625-120.292969 149.582031-120.292969 84.5625 0 156 59.53125 156 130 0 30.558594-12.496094 60.257812-35.183594 83.625zm0 0" />
                  <path d="m256 260h-126c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h126c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0" />
                  <path d="m256 320h-166c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h166c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0" />
                  <path d="m422 100h-126c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h126c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0" />
                </svg>
              </div>
              <h3 className="text-white py-3">We hear what you need</h3>
              <p className="text-white">
                We have a chat about what you'd like to add or fix on your
                website. Usually getting started with a few references or
                competitor links that you either need the theme to be inspired
                by or need your theme to be better than.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            {/* Single Work */}
            <div className="single-work text-center p-3">
              {/* Work Icon */}
              <div className="work-icon">
                {/*?xml version="1.0" encoding="UTF-8"?*/}
                <svg
                  width="424pt"
                  height="424pt"
                  viewBox="0 -25 424 424"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m167.29 272.13c-1.6016 0-3.2148-0.55078-4.5312-1.6719l-45.297-38.57c-1.5625-1.332-2.4609-3.2773-2.4609-5.332 0-2.0508 0.90234-3.9961 2.4609-5.3281l45.297-38.57c2.9414-2.5078 7.3594-2.1523 9.8672 0.78906 2.5039 2.9453 2.1523 7.3633-0.79297 9.8672l-39.035 33.242 39.035 33.242c2.9453 2.5078 3.2969 6.9258 0.79297 9.8672-1.3867 1.6289-3.3555 2.4648-5.3359 2.4648z" />
                  <path d="m256.71 272.13c-1.9805 0-3.9492-0.83594-5.332-2.4648-2.5078-2.9414-2.1523-7.3594 0.78906-9.8672l39.035-33.242-39.035-33.242c-2.9414-2.5039-3.2969-6.9219-0.78906-9.8672 2.5039-2.9414 6.9258-3.2969 9.8633-0.78906l45.297 38.57c1.5625 1.332 2.4609 3.2773 2.4609 5.3281 0 2.0547-0.89844 4-2.4609 5.332l-45.297 38.57c-1.3164 1.1211-2.9297 1.6719-4.5312 1.6719z" />
                  <path d="m195.96 301.06c-0.48047 0-0.96484-0.050781-1.4531-0.15234-3.7812-0.79688-6.2031-4.5117-5.4062-8.293l28.23-134.04c0.79687-3.7812 4.5078-6.2031 8.293-5.4062 3.7812 0.79688 6.2031 4.5078 5.4062 8.293l-28.23 134.04c-0.69531 3.2969-3.6016 5.5586-6.8398 5.5586z" />
                  <path d="m377 374.09h-330c-25.914 0-47-21.082-47-47v-280.09c0-25.914 21.086-47 47-47h330c25.914 0 47 21.086 47 47v280.09c0 25.918-21.086 47-47 47zm-330-360.09c-18.195 0-33 14.805-33 33v280.09c0 18.195 14.805 33 33 33h330c18.195 0 33-14.805 33-33v-280.09c0-18.195-14.805-33-33-33z" />
                  <path d="m417 112.09h-410c-3.8672 0-7-3.1328-7-7 0-3.8633 3.1328-7 7-7h410c3.8672 0 7 3.1367 7 7 0 3.8672-3.1328 7-7 7z" />
                  <path d="m119.6 78.594c-12.211 0-22.152-9.9414-22.152-22.152 0-12.215 9.9414-22.152 22.152-22.152 12.215 0 22.152 9.9375 22.152 22.152 0 12.211-9.9375 22.152-22.152 22.152zm0-30.305c-4.4922 0-8.1523 3.6562-8.1523 8.1523s3.6602 8.1523 8.1523 8.1523c4.4961 0 8.1523-3.6562 8.1523-8.1523s-3.6562-8.1523-8.1523-8.1523z" />
                  <path d="m51.539 78.379c-12.215 0-22.152-9.9375-22.152-22.152 0-12.215 9.9375-22.152 22.152-22.152 12.215 0 22.152 9.9375 22.152 22.152 0 12.215-9.9375 22.152-22.152 22.152zm0-30.305c-4.4922 0-8.1523 3.6602-8.1523 8.1523 0 4.4961 3.6602 8.1523 8.1523 8.1523 4.4961 0 8.1523-3.6562 8.1523-8.1523 0-4.4922-3.6562-8.1523-8.1523-8.1523z" />
                  <path d="m187.66 78.805c-12.211 0-22.148-9.9375-22.148-22.152 0-12.211 9.9375-22.148 22.148-22.148 12.215 0 22.152 9.9375 22.152 22.148 0 12.215-9.9375 22.152-22.152 22.152zm0-30.305c-4.4922 0-8.1484 3.6562-8.1484 8.1523s3.6562 8.1523 8.1484 8.1523c4.4961 0 8.1523-3.6562 8.1523-8.1523s-3.6562-8.1523-8.1523-8.1523z" />
                </svg>
              </div>
              <h3 className="text-white py-3">We organize the tasks</h3>
              <p className="text-white">
                From there we analyze the sections, snippets and or pages on the
                reference website and summarize all the features requested onto
                a Google Sheets list. Upon your approval we start working.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            {/* Single Work */}
            <div className="single-work text-center p-3">
              {/* Work Icon */}
              <div className="work-icon">
                {/*?xml version="1.0" encoding="UTF-8"?*/}
                <svg
                  enableBackground="new 0 0 512 512"
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m480.45 40.081h-448.9c-17.397 0-31.549 14.152-31.549 31.548v304.61c0 17.396 14.152 31.548 31.549 31.548h151.83l-6.141 49.129h-25.444c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h208.42c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-25.443l-6.142-49.129h151.83c17.396 0 31.549-14.152 31.549-31.548v-304.61c0-17.396-14.152-31.548-31.549-31.548zm-288.1 416.84l6.142-49.129h27.603l2.708 10.83c3.126 12.503 14.31 21.234 27.197 21.234s24.071-8.731 27.197-21.234l2.708-10.83h27.603l6.14 49.129h-127.3zm49.206-49.129h28.885l-1.798 7.192c-1.453 5.813-6.652 9.872-12.645 9.872s-11.191-4.06-12.645-9.872l-1.797-7.192zm255.44-31.548c0 9.125-7.424 16.548-16.549 16.548h-448.9c-9.125 0-16.549-7.423-16.549-16.548v-304.61c0-9.125 7.424-16.548 16.549-16.548h448.9c9.125 0 16.549 7.423 16.549 16.548v304.61z" />
                  <path d="m424.34 72.146h-384.77c-4.143 0-7.5 3.357-7.5 7.5v288.58c0 4.143 3.357 7.5 7.5 7.5h16.033c4.143 0 7.5-3.357 7.5-7.5 1e-3 -4.142-3.357-7.5-7.499-7.5h-8.533v-273.58h377.27c4.143 0 7.5-3.358 7.5-7.5 0-4.143-3.357-7.5-7.5-7.5z" />
                  <path d="m472.44 72.146h-16.033c-4.143 0-7.5 3.357-7.5 7.5 0 4.142 3.357 7.5 7.5 7.5h8.533v273.58h-377.27c-4.143 0-7.5 3.358-7.5 7.5 0 4.143 3.357 7.5 7.5 7.5h384.77c4.143 0 7.5-3.357 7.5-7.5v-288.58c1e-3 -4.143-3.357-7.5-7.499-7.5z" />
                  <path d="m358.1 155.18c-1.348-1.723-3.381-2.771-5.565-2.87l-176.35-8.017c-0.138-6e-3 -0.273-9e-3 -0.412-7e-3 -1.044-3.427-3.25-6.399-6.236-8.391l-21.597-14.398c-3.446-2.297-8.103-1.366-10.4 2.081s-1.366 8.103 2.08 10.4l21.596 14.396c0.102 0.068 0.177 0.171 0.211 0.289l27.509 96.281c0.858 3.002 2.27 5.7 4.083 8.03l-10.065 20.13c-2.423 4.845-2.169 10.488 0.679 15.096 1.033 1.671 2.343 3.094 3.848 4.234-1.97 3.439-3.103 7.417-3.103 11.657 0 12.976 10.557 23.532 23.532 23.532s23.532-10.557 23.532-23.532c0-3.009-0.574-5.885-1.608-8.532h68.377c-1.034 2.647-1.608 5.523-1.608 8.532 0 12.976 10.557 23.532 23.532 23.532s23.532-10.557 23.532-23.532c0-3.764-0.893-7.321-2.471-10.479 1.514-1.372 2.471-3.348 2.471-5.553 0-4.142-3.357-7.5-7.5-7.5h-139.34c-0.107 0-0.288 0-0.439-0.245-0.151-0.244-0.07-0.405-0.022-0.502l9.272-18.543c1.887 0.485 3.853 0.747 5.865 0.747 0.927 0 1.863-0.055 2.805-0.165l105.39-12.398c13.611-1.602 24.562-11.706 27.25-25.146l12.606-63.034c0.429-2.145-0.1-4.37-1.448-6.093zm-37.971 140.38c4.705 0 8.532 3.828 8.532 8.532 0 4.705-3.827 8.532-8.532 8.532s-8.532-3.827-8.532-8.532 3.827-8.532 8.532-8.532zm-112.22 0c4.705 0 8.532 3.827 8.532 8.532s-3.827 8.532-8.532 8.532-8.532-3.827-8.532-8.532 3.827-8.532 8.532-8.532zm-18.392-103.18l-9.399-32.895 29.985 1.363 5.199 31.532h-25.785zm23.042 54.571c-4.182 0.492-8.047-2.087-9.201-6.13l-9.555-33.441h23.972l6.301 38.216-11.517 1.355zm43.963-5.172l-17.532 2.063-6.011-36.461h23.543v34.398zm0-49.399h-26.017l-5.084-30.835 31.101 1.414v29.421zm37.982 44.93l-22.982 2.704v-32.634h25.691l-2.709 29.93zm4.067-44.93h-27.049v-28.74l29.529 1.342-2.48 27.398zm33.666 28.984c-1.41 7.048-7.154 12.349-14.293 13.189l-8.217 0.967 2.547-28.139h22.759l-2.796 13.983zm5.797-28.984h-24.401l2.418-26.716 27.08 1.231-5.097 25.485z" />
                </svg>
              </div>
              <h3 className="text-white py-3">We do the hard work</h3>
              <p className="text-white">
                We keep in touch throughout the whole working process so we're
                sure that everything is just as you wish. Payment is only after
                the project completion and your satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-bottom">
        <svg
          fill="#f6f9fe"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path className="fill" d="M0,6V0h1000v100L0,6z" />
        </svg>
      </div>
    </section>
  )
}

export default Steps
